@font-face {
  font-family: 'Roboto Mono';
  src: url(./static/fonts/roboto-mono-v7-latin-regular.woff2) format('woff2'),local('Roboto Mono');
}

@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/roboto-v20-latin-300.woff2) format('woff2'),local('Roboto Light');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/roboto-v20-latin-regular.woff2) format('woff2'),local('Roboto Regular');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/roboto-v27-latin-700.woff2) format('woff2'),local('Roboto Bold');
  font-weight: 700;
}

#jsd-widget {
  transform: scale(0.85);
  color-scheme: normal;
}

.material-icons {
  text-decoration: none !important;
}

div[role="tooltip"] {
  z-index: 100000000000;
}

* {
  font-family: Roboto;
  font-weight: 400;
  /* font-size: 16px; */
}

/* h1, h2, h3, h4, h5, h6, p {
  margin: 0;
} */

/* text {
  font-size: 11px;
} */

/* tspan, path { */
  /* font-size: 12px; */
/* } */

/* span { */
  /* font-size:12px */
/* } */


darkp {
  color: #333333;
}

/* div { */
  /* font-size: 13.75px; */
/* } */

/* hr {
  border: 1px solid #9B9B9B;
  width: 100%;
  opacity: 0.21;
  margin: 10px 0;
} */

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}
/*
.CodeMirror pre {
  font-size: 12px !important;
} */


.Modal-modal-0-1-3 {
  border-radius: 4px !important;
  overflow:  hidden !important;
}

option {
  background-color: #DAE6F0;
  color: black;
}

.react-contextmenu {
  z-index: 100000000000;
}

.apexcharts-tooltip-text {
  color: #000;
}

.apexcharts-tooltip-title {
  color: #000;
}

[class*="MuiPickersDay-isDisabled-"] {
  color: #888 !important;
}

.kada-info-box {
  padding: 16px 24px;
  border-radius: 3px;
  border: 1px solid var(--color-base-border);
  margin-bottom: 40px;
  box-sizing: border-box;
  max-width: 1920px;
}

.kada-info-box-description {
  color: var(--color-light-text);
  white-space: pre-wrap;
  margin-bottom: 20px;
  margin-right: 40px;
  flex-shrink: 1;
  flex-grow: 1;
}
